import React from 'react';
import Image from './image';

export default function Footer() {
  return (
    <div className='bg-secondary m-16'>
      <div className='container'>
        <div className='flex items-start justify-between flex-col xl:flex-row'>
          <div>
            <div className='flex-1'>
              <div className='mb-4 xl:mb-8'>
                <Image src='footer-logo.png' alt='' />
              </div>
              <div className='flex items-center mb-4 xl:mb-8 space-x-3 rtl:space-x-reverse'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
