import PropTypes from 'prop-types';
import React from 'react';
// import Header from './header';
// import Navbar from './common/navbar-nb';
import Footer from './common/footer';

function Layout({ children }) {
  return (
    <div className='flex flex-col min-h-screen font-sans text-gray-900'>
      <main className=''>{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
